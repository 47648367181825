<template name="component-name">
  <div>
    <!-- 
    <b-navbar toggleable="md" type="dark" class="my-navbar">
      <p class="flex">
       
        <img
          src="/img/tnllarge.png"
          alt="Toyota logo"
          class="whiteLogo logo-2xl-sized fill-white"
        />

     
        <img
          src="/img/logo-right.svg"
          alt="Toyota logo"
          class="whiteLogo logo-sized fill-white ml-3"
        />
      </p>
      <b-navbar-nav class="main-navigation">
        <b-nav-item
          to="/AccountStatus"
          :active="$route.name == 'AccountStatus'"
        >
          Account Status
        </b-nav-item>

        <b-nav-item-dropdown text="Vehicle Orders" right>
          <b-dropdown-item to="/home">New Vehicle Orders</b-dropdown-item>
          <b-dropdown-item to="/Pending">Payment Confirmation</b-dropdown-item>
          <b-dropdown-item to="/PlaceOrder">
            Place Order Vehicle
          </b-dropdown-item>
        </b-nav-item-dropdown>

        <b-nav-item-dropdown text="Parts Orders" right>
          <b-dropdown-item to="/PartsOrdering">Parts Ordering</b-dropdown-item>
          <b-dropdown-item to="/PartsAvailability">
            Parts Availability Check
          </b-dropdown-item>
        </b-nav-item-dropdown>

       

        <b-nav-item to="/OrderStatus" :active="$route.name == 'OrderStatus'">
          Order Status
        </b-nav-item>
        <b-nav-item to="/Processed" :active="$route.name == 'Processed'">
          Processed Orders
        </b-nav-item>
      </b-navbar-nav>

      <b-navbar-nav class="align-right">
        

        <b-nav-item-dropdown right>

          
          <template v-slot:button-content>
            <em>User: {{ loggedInUser.FullName }}</em>
          </template>
          <b-dropdown-item href="#">Profile</b-dropdown-item>
          <b-dropdown-item href="/">Sign Out</b-dropdown-item>
        </b-nav-item-dropdown>
      </b-navbar-nav>
    </b-navbar>
  -->
    <b-navbar toggleable="md" type="dark" class="my-navbar">
      <p class="flex">
        <!-- <img src="/img/tnlwhite.png" alt="Toyota logo" class="whiteLogo logo-sized" /> -->
        <img
          src="/img/tnllarge.png"
          alt="Toyota logo"
          class="whiteLogo logo-2xl-sized fill-white"
          style="padding-right: 15px;"
        />
        <img
          src="/img/logo-right.svg"
          alt="Toyota logo"
          class="whiteLogo logo-sized fill-white"
        />
      </p>
      <b-navbar-nav class="main-navigation">
        <b-nav-item to="/AccountStatus" :active="$route.name == 'AccountStatus'"
          >Account Status</b-nav-item
        >

        <b-nav-item-dropdown text="Parts Orders" right>
          <b-dropdown-item to="/PartsOrdering">Parts Ordering</b-dropdown-item>
          <b-dropdown-item to="/PartsAvailability">
            Parts Availability Check
          </b-dropdown-item>
          <b-dropdown-item to="/PartsPrevious">
            Previous Parts Order
          </b-dropdown-item>
          <b-dropdown-item to="/PartsPre">
            Previous Parts Order - Detail
          </b-dropdown-item>
        </b-nav-item-dropdown>

        <b-nav-item to="/home" :active="$route.name == 'Home'"
          >New Vehicle Orders</b-nav-item
        >
        <b-nav-item to="/Pending" :active="$route.name == 'Pending'"
          >Payment Confirmation</b-nav-item
        >
        <b-nav-item to="/OrderStatus" :active="$route.name == 'OrderStatus'"
          >Order Status</b-nav-item
        >
        <b-nav-item to="/Processed" :active="$route.name == 'Processed'"
          >Processed Orders</b-nav-item
        >
      </b-navbar-nav>
      <!-- <b-navbar-nav>
          <b-nav-item to="/coupons">Coupons</b-nav-item>
      </b-navbar-nav>-->

      <!-- Right aligned nav items -->
      <b-navbar-nav class="align-right">
        <!-- <b-nav-form>
            <b-form-input
              size="sm"
              class="mr-sm-2"
              placeholder="Search"
            ></b-form-input>
            <b-button size="sm" class="my-2 my-sm-0" type="submit"
              >Search</b-button
            >
        </b-nav-form>-->

        <b-nav-item-dropdown right>
          <!-- Using 'button-content' slot -->
          <template v-slot:button-content>
            <em>User: {{ loggedInUser.FullName }}</em>
          </template>
          <b-dropdown-item href="#">Profile</b-dropdown-item>
          <b-dropdown-item href="/DealerUser">Manage Users</b-dropdown-item>
          <b-dropdown-item href="/">Sign Out</b-dropdown-item>
        </b-nav-item-dropdown>
      </b-navbar-nav>
    </b-navbar>
  </div>
</template>

<script>
import { getData } from "./../../utils/storage";
export default {
  data() {
    return {
      loggedInUser: ""
    };
  },
  created() {
    //alert("my header is here");
    this.loggedInUser = getData("userInfo");
    //console.log(this.loggedInUser);
  }
};
</script>

<style scoped>
.flex {
  display: flex;
}

.flex > * + * {
  margin-left: 20px;
}
.fill-white {
  fill: white !important;
}
.align-right {
  margin-left: auto;
}

.logo-2xl-sized {
  transform: scale(1.3);
  object-fit: fill;
  object-position: top;
}
.logo-sized {
  height: 120px;
  width: 100px;
  object-fit: fill;
  object-position: top;
}
.my-navbar {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  font-weight: bold;
  min-height: 100px;
  padding: 0 20px;
  z-index: 32;
  background-color: #000 !important;
  display: grid;
  grid-template-columns: 15% 70% 15%;
}

.my-navbar img {
  margin: 4px;
}
.my-navbar p {
  color: #fff;
  font-weight: bold;
}
.my-navbar .main-navigation {
  text-align: center;
  margin: 10px auto;
  position: center;
  font-size: 1.1rem;
}

.my-navbar .active {
  border-bottom: solid 1px white;
}
</style>
